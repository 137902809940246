body {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 544px) {
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 992px) {
  body {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1200px) {
}
.text-uppercase {
  text-transform: uppercase;
}

.bg-deep-red {
  background-color: #B40404;
}

.border-radius-top {
  border-radius: 0 0 5px 5px;
}

.heading-text h1 {
  font-size: 2em;
}

.heading-text h3 {
  font-size: 1.25em;
}

.rounded-button {
}

.btn-online:focus,
.btn-online:hover,
.btn-online:active {
  color: #B40404;
}

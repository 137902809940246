body {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 544px) {
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 992px) {
  body {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1200px) {
}